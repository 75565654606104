import { AppHeaderConfigPayload } from 'admin-portal-shared-services';
import { t } from 'i18next';
import { Modules } from 'routes/Definition';

export const defaultAppHeaderConfig: AppHeaderConfigPayload = {
  pageTitle: ' ',
  breadcrumbConfig: {
    homePath: '',
    items: [],
  },
  vendorSelect: false,
  countrySelect: false,
  countryOptions: [],
};

export const getAppHeaderConfig: (pageName: Modules) => Promise<AppHeaderConfigPayload> = async (
  pageName
) => {
  const {
    homePath,
    commercialControlPath,
    chainPath,
    deliveryListPath,
    deliveryDetailsPath,
    ordersPath,
  } = await import('routes/Definition');
  const [, , , param4, param5, param6, param7] = window.location.pathname.split('/');
  const informationCenterPath = `${commercialControlPath}/bees-information-center`;

  const getItemsCommercialControl = () => [
    {
      isCurrentPage: true,
      path: '',
      label: t('commercialControl:moduleName'),
    },
  ];

  const getItemsInformationCenter = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: true,
      path: '',
      label: t('informationCenter:moduleName'),
    },
  ];

  const getItemsDeliveryList = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('informationCenter:moduleName'),
    },
    {
      isCurrentPage: true,
      path: deliveryListPath,
      label: t('deliveryList:moduleName'),
    },
  ];

  const getItemsDeliveryDetails = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('informationCenter:moduleName'),
    },
    {
      isCurrentPage: false,
      path: deliveryListPath,
      label: t('deliveryList:moduleName'),
    },
    {
      isCurrentPage: true,
      path: deliveryDetailsPath,
      label: t('deliveryDetails:moduleName'),
    },
  ];

  const getItemsOrders = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('informationCenter:moduleName'),
    },
    {
      isCurrentPage: true,
      path: ordersPath,
      label: t('ordersPage:moduleName'),
    },
  ];

  const getItemsChain = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: false,
      path: informationCenterPath,
      label: t('informationCenter:moduleName'),
    },
    {
      isCurrentPage: true,
      path: '',
      label: t('chain:moduleName'),
    },
  ];

  const getItemsStore = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: false,
      path: informationCenterPath,
      label: t('informationCenter:moduleName'),
    },
    {
      isCurrentPage: false,
      path: `${chainPath}/${param4}/${param5}`,
      label: t('chain:moduleName'),
    },
    {
      isCurrentPage: true,
      path: '',
      label: t('store:moduleName'),
    },
  ];

  const getItemsChainDelivery = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: false,
      path: informationCenterPath,
      label: t('informationCenter:moduleName'),
    },
    {
      isCurrentPage: false,
      path: `${chainPath}/${param4}/${param5}`,
      label: t('chain:moduleName'),
    },
    {
      isCurrentPage: true,
      path: '',
      label: t('delivery:moduleName'),
    },
  ];

  const getItemsStoreDelivery = () => [
    {
      isCurrentPage: false,
      path: commercialControlPath,
      label: t('commercialControl:moduleName'),
    },
    {
      isCurrentPage: false,
      path: informationCenterPath,
      label: t('informationCenter:moduleName'),
    },
    {
      isCurrentPage: false,
      path: `${chainPath}/${param4}/${param5}`,
      label: t('chain:moduleName'),
    },
    {
      isCurrentPage: false,
      path: `${chainPath}/${param4}/${param5}/${param6}/${param7}`,
      label: t('store:moduleName'),
    },
    {
      isCurrentPage: true,
      path: '',
      label: t('delivery:moduleName'),
    },
  ];

  switch (pageName) {
    case 'COMMERCIAL_CONTROL':
      return {
        pageTitle: t('commercialControl:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsCommercialControl(),
        },
      };
    case 'DELIVERY_LIST':
      return {
        pageTitle: t('deliveryList:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsDeliveryList(),
        },
      };
    case 'DELIVERY_DETAILS':
      return {
        pageTitle: t('delivery:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsDeliveryDetails(),
        },
      };
    case 'ORDERS':
      return {
        pageTitle: t('ordersPage:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsOrders(),
        },
      };
    case 'INFORMATION_CENTER':
      return {
        pageTitle: t('informationCenter:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsInformationCenter(),
        },
      };
    case 'CHAIN':
      return {
        pageTitle: decodeURIComponent(param4),
        breadcrumbConfig: {
          homePath,
          items: getItemsChain(),
        },
      };
    case 'STORE':
      return {
        pageTitle: decodeURIComponent(param6),
        breadcrumbConfig: {
          homePath,
          items: getItemsStore(),
        },
      };
    case 'CHAIN_DELIVERY':
      return {
        pageTitle: t('delivery:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsChainDelivery(),
        },
      };
    case 'STORE_DELIVERY':
      return {
        pageTitle: t('delivery:moduleName'),
        breadcrumbConfig: {
          homePath,
          items: getItemsStoreDelivery(),
        },
      };
  }
};
