import { EmptyState } from '@hexa-ui/components';
import { useTranslation } from 'react-i18next';
import { GET_HELP_URL } from '@constants';
import displayStateErrorImg from '../../../assets/display-state-error.svg';
import displayStateEmptyImg from '../../../assets/display-state-empty.svg';
import * as Styles from './DisplayState.styles';

interface IDisplayState {
  type: 'error' | 'empty';
  title?: string;
  description?: string;
  hasButton: boolean;
}

export const DisplayState = ({ type, title, description, hasButton }: IDisplayState) => {
  const { t } = useTranslation('common');
  let alt = '';
  let imgSrc = '';

  switch (type) {
    case 'error': {
      (alt = 'Error status image'), (imgSrc = displayStateErrorImg);
      break;
    }
    case 'empty': {
      (alt = 'Empty status image'), (imgSrc = displayStateEmptyImg);
      break;
    }
  }

  const getHelpAction = (): [
    firstAction: { action: () => void; name: string; variant: 'primary' | 'secondary' }
  ] => {
    return [
      {
        action: () => window.open(GET_HELP_URL, '_blank'),
        name: t('button.getHelp'),
        variant: 'secondary',
      },
    ];
  };
  return (
    <Styles.ComponentWrapper>
      <EmptyState.PageLevel
        title={title}
        description={description}
        actions={hasButton ? getHelpAction() : undefined}
        customIlustration={{
          alt,
          width: '200px',
          height: '200px',
          src: imgSrc,
        }}
      />
    </Styles.ComponentWrapper>
  );
};
