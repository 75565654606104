import { useQuery } from '@tanstack/react-query';
import { useAppHeader } from 'admin-portal-shared-services';
import { useApi } from 'api/api';
import { getFormattedDate } from './getFormattedDate/getFormattedDate';
import { IGetOrders, IGetOrdersResponse } from './useGetOrders.types';

const API_ORDER_TRACKING_URL = 'api/link-order-tracking/v3';

export const useGetOrders = ({
  accountId,
  orderStatus,
  orderNumber,
  poNumber,
  orderRegions,
  restriction,
  page,
  pageSize,
  includeStatus,
  deliveryStartDate,
  deliveryEndDate,
  startDate,
  endDate,
  needsAttention,
  inTransit,
  vendorIds,
  vendorOrderNumber,
}: IGetOrders) => {
  const { api, requestTraceId } = useApi();
  const [getAppHeaderConfig] = useAppHeader();
  const { selectedCountry, defaultCountry } = getAppHeaderConfig;

  const _deliveryStartDate = deliveryStartDate && getFormattedDate(deliveryStartDate);
  const _deliveryEndDate = deliveryEndDate && getFormattedDate(deliveryEndDate);
  const _startDate = startDate && getFormattedDate(startDate);
  const _endDate = endDate && getFormattedDate(endDate);
  const country = selectedCountry ?? defaultCountry;

  const {
    data: response,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
  } = useQuery<IGetOrdersResponse>(
    [
      'orders',
      accountId,
      orderStatus,
      orderNumber,
      poNumber,
      orderRegions,
      restriction,
      page,
      pageSize,
      includeStatus,
      deliveryStartDate,
      deliveryEndDate,
      startDate,
      endDate,
      needsAttention,
      inTransit,
      vendorIds,
      vendorOrderNumber,
    ],
    async () => {
      const response = await api.post<IGetOrdersResponse>({
        url: API_ORDER_TRACKING_URL,
        data: {
          accountId,
          orderStatus,
          orderNumber,
          poNumber,
          vendorOrderNumber,
          orderRegions,
          restriction,
          page,
          pageSize,
          includeStatus,
          deliveryStartDate: _deliveryStartDate,
          deliveryEndDate: _deliveryEndDate,
          startDate: _startDate,
          endDate: _endDate,
          needsAttention,
          inTransit,
          vendorIds,
        },
        config: {
          headers: {
            country: country,
          },
        },
      });
      return { ...response.data, requestTraceId };
    },
    { cacheTime: 0 }
  );

  const data: IGetOrdersResponse = {
    content: response?.content ?? [],
    pagination: response?.pagination ?? {
      page: 0,
      pageSize: 0,
      totalPages: 0,
      totalElements: 0,
    },
    totals: response?.totals ?? {
      total: 0,
      totalInProgress: 0,
      totalNeedAttention: 0,
      totalCancelled: 0,
      totalCompleted: 0,
      percentageInProgress: 0,
      percentageNeedAttention: 0,
      percentageCancelled: 0,
      percentageCompleted: 0,
    },
  };

  return {
    data,
    isLoading,
    isError,
    error,
    isFetching,
    refetch,
    requestTraceID: requestTraceId,
  };
};
